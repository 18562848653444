<template>
  <div class="collection-form">
      <portlet :title="portletTitle">
        <template v-slot:body>
          <b-form ref="collection" @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-form-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>

            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-8" label="توپلام ماۋزوسى" label-for="title" description="نۆۋەتتىكى توپلامنىڭ ماۋزوسىنى كىرگۈزۈڭ">
                  <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="توپلام ماۋزوسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="title-live-feedback">
                    توپلام ماۋزوسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-form-row>

            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
  </div>
</template>
<script>
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import vcolorpicker from "vcolorpicker";
  import Vue from 'vue';
  import { validationMixin } from "vuelidate";
  import collectionMixin from "@m@/collectionMixin";
  import { required } from "vuelidate/lib/validators";
  Vue.use(vcolorpicker);
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";

  export default {
    name: "collectionForm",
    mixins: [ formBusyMixin, validationMixin, collectionMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    validations: {
      formData: {
        title:          { required }
      }
    },
    components: { Portlet, ImageUploader },
    computed: {
      portletTitle(){
        let create = "يېڭىدىن توپلام قوشۇش";
        let edit = "توپلام ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
      oldImage(){
        return this.action === 'edit' && this.formData.icon_image || '';
      },
    },
    created() {
      if ( this.action === 'edit' ){
        let id = this.$route.params.id;
        this.findCategory(id)
          .then(({data})=>{
            this.formData = data;
          })
          .catch(()=>{
            return this.$router.replace({name: 'collection.list'});
          })
      }
    },
    data(){
      return {
        formData: {
          title: '',
          is_show: true,
        }
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        if ( this.action === 'create' ){
          this.createCategory(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'collection.list'});
            })
        }else if ( this.action === 'edit' ){
          this.updateCategory(this.formData)
            .then(()=>{
              this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
              return this.$router.replace({name: 'collection.list'});

            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'collection.list'});
      },
      onUploadSuccess(response){
        this.formData.icon_image = response.path;
        this.formData.preview = response.url;
      },
      changeBackground(e){
        this.formData.background = e;
      }
    }
  }
</script>
